<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.hub_id" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option value="all_hub" selected>All hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="excelDownload()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Download</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'List',
  data() {
    return {
      hubs: [],
      index: -1,
      pdfDownloadLoader: false,
      loading: false,
      btnLoading: false,
      loader: false,
      flag: false,
      search_data: {
        hub_id: 'all_hub',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    async codes() {
      await apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    excelDownload() {
      this.btnLoading = true
      apiClient.post('api/report/hub-currently-stock/excel-download', this.search_data, { responseType: 'blob' }).then(response => {
        this.btnLoading = false
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'hub_currently_stock_report.xlsx')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
